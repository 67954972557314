@import "@/styles/mixins";

.bootcampCard {
  &:hover {
    text-decoration: none;
  }

  .thumbnail {
    position: relative;
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    height: 151px;
    margin-bottom: 18px;

    @include desktop {
      height: 200px;
      margin-bottom: 24px;
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      max-width: unset;
      height: 100%;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 154px;
    justify-content: space-between;

    @include desktop {
      min-height: 208px;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 0 4.5px;

      @include desktop {
        gap: 16px;
        padding: 0 6px;
      }

      .tag {
        color: #444CE7;
        font-weight: 700;
        font-size: 10px;
        line-height: 13.02px;
        letter-spacing: 0.32px;
        font-family: var(--dm-sans);

        @include desktop {
          font-size: 13px;
          line-height: 16.93px;
        }
      }

      .title {
        color: #101828;
        font-family: var(--dm-sans);
        font-size: 16px;
        line-height: 20.83px;
        font-weight: 600;
        @include line-clamp(2);

        @include desktop {
          font-size: 24px;
          line-height: 31.25px;
        }
      }

      .description {
        color: #1D2939;
        font-family: var(--dm-sans);
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        opacity: 0.8;
        @include line-clamp(3);

        @include desktop {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .bottomContent {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0 4.5px;

      @include desktop {
        gap: 16px;
        padding: 0 6px;
      }

      .text {
        color: #1D2939;
        font-family: var(--man-700);
        font-size: 11px;
        line-height: 15.03px;
        letter-spacing: 0.32px;
        opacity: 0.8;
        text-transform: uppercase;

        @include desktop {
          font-size: 13px;
          line-height: 16.93px;
          opacity: 0.6;
        }
      }
    }
  }
}