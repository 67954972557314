@import "@/styles/mixins";

.faqComponent{
    :global(.faqSectionWrapper){
        :global(.faqSection){
            :global(.topSection){
                :global(.title){
                    text-align: center;
                    font-size: 32px;
                    line-height: 41.66px;
                    font-weight: 600;
                    font-family: var(--dm-sans);
                    color: #101828;
                    @include desktop{
                        font-size: 48px;
                        line-height: 62.5px;
                    }
                }
            }
            :global(.bottomSection){
                :global(.accordion){
                    :global(.header){
                        :global(.title){
                            font-weight: 600;
                            @include desktop{
                                line-height: 24px;
                            }
                        }
                    }
                    :global(.content){
                        :global(.accordionAnswer){
                            opacity: 1;
                            color: #1D2939;
                        }
                    }
                }
            }
        }
    }
}