@import "@/styles/mixins";

.bootcampCarousel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .conatiner {
    padding: 0px;
    max-width: 100%;
    @include desktop{
      max-width: 1358px;
    }

    :global(.slick-slider) {
      position: relative;

      @include desktop {
        margin: 0 -16px;
        padding: 0 64px;
      }

      :global(.slick-list) {

        @include desktop {
          width: 1200px;
        }

        @include mobile {
          padding-left: 1rem;
        }

        :global(.slick-track) {
          @include mobile {
            display: flex;
            gap: 24px;
            max-width: fit-content;
          }

          @include desktop {
            margin: 0 12px;
          }

          :global(.slick-slide) {
            width: 290px !important;

            @include desktop {
              padding: 0 12px;
              width: 398px !important;
            }
          }
        }
      }
    }

    :global(.slick-arrow) {
      border: none !important;
      background-color: transparent !important;
      box-shadow: none !important;
      padding: 0 !important;
      position: absolute;
      // top: 50%;
      transform: translateY(-50%);
      padding: 0;
      height: 48px;
      width: 48px;
      z-index: 2;

      >div {
        background: none !important;
        height: 48px;
        width: 48px;
      }

      &::before {
        content: none;
      }

      svg {
        height: 48px;
        width: 48px;
      }
    }

    :global(.slick-prev) {
      left: 0;
    }

    :global(.slick-next) {
      right: 0;
    }
  }
}