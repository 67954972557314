@import "@/styles/mixins";

.footer-wrapper {
  padding: 56px 120px 32px 120px;
  background-color: #101828;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (min-width: 601px) and (max-width: 1200px) {
    padding: 56px 20px 32px 20px;
    gap: 48px;
  }

  @include mobile {
    gap: 48px;
    padding: 56px 20px 32px 20px;
  }

  .footer-socials {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 97px;
    align-items: center;

    @media screen and (min-width: 601px) and (max-width: 1200px) {
      flex-direction: column;
      height: max-content;
      gap: 40px;
    }

    @include mobile {
      flex-direction: column;
      height: max-content;
      gap: 40px;
    }

    .shyft-icon {
      width: 131px;

      @media screen and (min-width: 601px) and (max-width: 1200px) {
        height: 48px;
      }

      @include mobile {
        height: 48px;
        width: 111px;
      }

      svg {
        width: 100%;

        @media screen and (min-width: 601px) and (max-width: 1200px) {
          height: 100%;
        }

        @include mobile {
          height: 100%;
        }
      }
    }

    .mid-links {
      display: flex;
      flex-direction: column;
      gap: 21px;
      justify-content: space-between;

      @media screen and (min-width: 601px) and (max-width: 1200px) {
        gap: 24px;
      }

      @include mobile {
        gap: 24px;
      }

      .mid-top {
        margin: 0 8.5px;

        @media screen and (min-width: 601px) and (max-width: 1200px) {
          margin: 0 13px;
        }

        @include mobile {
          margin: 0 13px;
        }
      }
      .mid-top,
      .mid-bottom {
        display: flex;
        justify-content: space-between;
        gap: 24px;

        p {
          font-family: var(--dm-sans);
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.4px;
          opacity: 0.8;
          color: #ffffff;
          cursor: pointer;

          @media screen and (min-width: 601px) and (max-width: 1200px) {
            font-size: 15px;
          }

          @include mobile {
            font-size: 15px;
          }
        }
      }
    }

    .social-links {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-end;

      @media screen and (min-width: 601px) and (max-width: 1200px) {
        align-items: center;
        gap: 32px;
      }

      @include mobile {
        align-items: center;
        gap: 32px;
      }

      .sl-top {
        display: flex;
        gap: 12px;

        @include mobile {
          gap: 16px;
        }

        div {
          width: 32px;
          height: 32px;

          svg {
            cursor: pointer;
            width: 100%;
            height: 100%;
          }
          .icon-image{
            cursor: pointer;
          }
        }
      }

      .sl-bottom {
        display: flex;
        gap: 10px;

        img {
          cursor: pointer;
          width: 138px;
          height: 45px;

          @media screen and (min-width: 601px) and (max-width: 1200px) {
            width: 151px;
            height: 49px;
          }

          @include mobile {
            width: 151px;
            height: 49px;
          }
        }
      }
    }
  }

  .footer-tncs {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;

    p {
      font-family: var(--dm-sans);
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #ffffff;
      letter-spacing: 0.4px;
      opacity: 0.6;
      cursor: pointer;

      @include mobile {
        font-family: var(--man-500);
      }
    }
  }
}
