@import "@/styles/mixins";

.cardWrapper{
    border-radius: 10.24px;
    border: 0.64px solid #0000000D;
    box-shadow: 0px 2.56px 10.24px 0px #0000000D;
    background-color: #FFFFFF;
    padding: 19.84px 20.48px;
    display: flex;
    flex-direction: column;
    gap: 10.24px;
    align-items: flex-start;
    @include desktop{
        min-height: 306px;
        border-width: 1px;
        border-radius: 16px;
        padding: 31px 32px;
        gap: 24px;
    }
    .quoteWrapper{
        width: 20.48px;
        height: 16.87px;
        display: flex;
        align-items: center;
        @include desktop{
            width: 32px;
            height: 26.37px;
        }
        .quoteClass{
            width: 100%;
            height: 100%;
        }
    }
    .description{
        font-size: 13px;
        line-height: 19.2px;
        font-weight: 500;
        font-family: var(--dm-sans);
        color: #1D2939;
        @include line-clamp(4);
        @include mobile{
            margin-bottom: 4px;
        }
        @include desktop{
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
        }
    }
    .infoWrapper{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10.24px;
        @include desktop{
            gap: 16px;
        }
        .image{
            width: 30.72px;
            height: 30.72px;
            border-radius: 64px;
            @include desktop{
                width: 48px;
                height: 48px;
            }
        }
        .info{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 1.28px;
            @include desktop{
                gap: 2px;
            }
            .name{
                font-weight: 600;
                font-family: var(--dm-sans);
                font-size: 12px;
                line-height: 15.62px;
                color: #1D2939;
                @include desktop{
                    font-size: 16px;
                    line-height: 20.83px;
                    font-weight: 500;
                }
            }
            .age{
                font-weight: 500;
                font-family: var(--dm-sans);
                font-size: 12px;
                line-height: 15.62px;
                color: #1D2939;
                opacity: 0.6;
                @include desktop{
                    font-size: 16px;
                    line-height: 20.83px;
                }
            }
        }
    }
}