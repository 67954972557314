@import "@/styles/mixins";

.accordion {
  display: flex;
  width: 100%;
  padding: 16px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  overflow: hidden;
  border-radius: 10px;
  transition: height 0.3s ease-in-out;
  border: 1px solid #001f490d;
  cursor: pointer;

  @include desktop {
    flex-shrink: 0;
    padding: 27px 32px 27px 32px;
  }

  &.accordion-open {
    padding-bottom: 24px;
    border: none;
    background: #f9f9f9;
    border: 1px solid transparent;

    @include desktop {
      border-radius: 16px;
    }
  }

  &.accordion-open .content {
    max-height: 100rem;
    opacity: 1;
    margin-top: 8px;
  }

  .content {
    margin-top: 0px;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.6s ease-in-out, opacity 0.8s ease-in-out, margin-top 0.9s ease-in-out;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  transition: background-color 0.3s ease-in-out;
  width: 100%;

  .title {
    color: var(--primary-oxford-blue, #001f49);
    font-family: var(--dm-sans);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    flex: 1 0 0;

    @include desktop {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      max-width: 720px;
    }
  }

  .accordion-icon {
    position: relative;
    height: 24px;
    width: 24px;

    svg {
      height: inherit;
      width: inherit;
    }

    @include desktop {
      height: 32px;
      width: 35px;
    }
  }
}