@import "@/styles/mixins";

.productWrapper {
    background-color: #F3F3EF;

    @include desktop {
        background-color: #FFFEFC;
    }

    .contianer {
        padding-top: 36px;

        @include desktop {
            padding-top: 68px;
        }

        .heading {
            display: flex;
            flex-direction: column;
            gap: 8px;
            text-align: center;

            @include desktop {
                gap: 18px;
            }

            .title {
                font-size: 33px;
                line-height: 43.87px;
                font-weight: 600;
                font-family: var(--dm-sans);
                letter-spacing: -1px;
                color: #8E5537;

                @include desktop {
                    font-size: 60px;
                    line-height: 81.33px;
                }
            }

            .description {
                font-size: 18px;
                line-height: 28.25px;
                font-weight: 600;
                font-family: var(--dm-sans);
                color: #1D2939;
                opacity: 0.8;

                @include desktop {
                    font-size: 30px;
                    line-height: 39.66px;
                }
            }
        }

        .productSection {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;

            .imageWrapper {
                position: relative;
                height: 480px;
                width: 100%;

                @include desktop {
                    background: none;
                    width: 546px;
                    height: 678px;
                }

                .productImage {
                    width: 100%;
                    height: 100%;
                }
                .addToCart{
                    position: absolute;
                    bottom: 11.4%;
                    width: 133px;
                    height: 40px;
                    right: 35%;
                    cursor: pointer;
                    @include desktop{
                        right: 38%;
                    }
                }
                .viewReport {
                    position: absolute;
                    top: 8%;
                    left: 7%;
                    border-radius: 10px;
                    padding: 11px 15px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    background-color: #E4F6EC;
                    box-shadow: 0px 3.48px 3.48px 0px #0E5F3A1A;

                    @include desktop {
                        left: 4%;
                        top: 9%;
                    }

                    .icon {
                        width: 22px;
                        height: 22px;
                    }

                    .description {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19.12px;
                        color: #039855;
                        font-family: var(--circularStd);

                        span {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 19.12px;
                            color: #039855;
                            font-family: var(--circularStd);
                            border-bottom: 1px solid #039855;
                            cursor: pointer;
                        }
                    }

                }

                .proteinometerWrapper1 {
                    padding: 12px 20px 28px;
                    z-index: 2;
                    cursor: pointer;

                    @include mobile {
                        display: none;
                    }

                    @include desktop {
                        padding: 0;
                        position: absolute;
                        bottom: 12%;
                        right: -48%;
                    }

                    .proteinometer {
                        padding: 20.86px 13.9px;
                        display: flex;
                        align-items: center;
                        gap: 24px;
                        background-color: #4F6034;
                        border-radius: 10.43px;

                        @include desktop {
                            padding: 18px 13px;
                            gap: 16px;
                        }

                        .contentWrapper {
                            display: flex;
                            align-items: center;
                            gap: 13.9px;

                            .icon {
                                width: 34.76px;
                                height: 34.76px;
                            }

                            .description {
                                font-size: 16px;
                                line-height: 20.83px;
                                font-weight: 500;
                                font-family: var(--dm-sans);
                                color: #D4E4C3;

                                @include desktop {
                                    font-size: 14px;
                                    font-weight: 600;
                                }
                            }
                        }

                        .icon {
                            width: 20.86px;
                            height: 20.86px;
                        }
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100px;
                    /* Adjust height for the gradient */
                    pointer-events: none;
                    /* Prevent blocking interactions */
                    background: linear-gradient(180deg, rgba(255, 254, 252, 0) 0%, #F4F0EB 68.68%);

                    @include desktop {
                        background: linear-gradient(180deg, rgba(255, 254, 252, 0) 0%, #FFFEFC 89.29%);
                    }
                }
            }

            .leftSide {
                position: absolute;
                left: -36%;
                top: 20%;
                display: flex;
                flex-direction: column;
                gap: 62px;

                @include mobile {
                    display: none;
                }

                .top,
                .bottom {
                    position: relative;

                    .content {
                        display: flex;
                        align-items: center;
                        gap: 2px;

                        .iconWrapper {
                            width: 35px;
                            height: 35px;

                            .icon {
                                width: 34px;
                                height: 34px;
                            }
                        }

                        .description {
                            font-weight: 600;
                            color: #104246;
                            font-size: 13.9px;
                            line-height: 17.38px;
                            font-family: var(--dm-sans);

                            span {
                                font-size: 13.9px;
                                line-height: 17.38px;
                                font-weight: 500;
                                color: #104246;
                                font-family: var(--dm-sans);
                            }
                        }
                    }
                }

                .top {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    align-items: flex-start;

                    .content {
                        .description {
                            font-weight: 500;
                            color: #104246;
                            font-size: 15px;
                            line-height: 19px;
                            font-family: var(--circularStd);
                        }
                    }

                    .arrow1 {
                        position: absolute;
                        width: 127.5px;
                        height: 168.31px;
                        top: -8%;
                        right: -72%;
                    }
                }

                .bottom {
                    max-width: 169.17px;
                    display: flex;
                    flex-direction: column;
                    gap: 15.38px;
                    align-items: flex-start;

                    .content {
                        justify-content: flex-start;
                        gap: 8.69px;
                        .iconWrapper {
                            width: 30.42px;
                            height: 30.42px;

                            .icon {
                                width: 28.42px;
                                height: 28.42px;
                            }
                        }

                        .description {
                            font-weight: 500;
                            font-family: var(--circularStd);
                        }
                    }

                    .actionButton {
                        border: 0.87px solid #101828;
                        padding: 5.21px 12.04px;
                        border-radius: 6.08px;
                        background: transparent;
                        position: absolute;
                        bottom: -46%;
                        width: 148.07px;
                        height: 31.29px;
                        right: 3%;
                        cursor: pointer;

                        p {
                            font-weight: 500;
                            font-size: 11.3px;
                            line-height: 14.71px;
                            color: #101828;
                            font-family: var(--dm-sans);
                        }
                    }

                    .arrow2 {
                        position: absolute;
                        width: 170.21px;
                        height: 142px;
                        bottom: -4px;
                        right: -95%;
                    }
                }
            }

            .rightSide {
                position: absolute;
                right: -39%;
                top: 25.5%;
                display: flex;
                flex-direction: column;
                gap: 6.2rem;

                @include mobile {
                    display: none;
                }

                .top,
                .bottom {
                    position: relative;

                    .content {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .iconWrapper {
                            width: 35px;
                            height: 35px;

                            .icon {
                                width: 35px;
                                height: 35px;
                            }
                        }

                        .description {
                            font-weight: 700;
                            color: #104246;
                            font-size: 18.83px;
                            line-height: 0px;
                            font-family: var(--circularStd);

                            span {
                                font-size: 13.64px;
                                line-height: 17.79px;
                                font-weight: 700;
                                color: #104246;
                                font-family: var(--dm-sans);
                            }
                        }
                    }

                    .actionButton {
                        border: 0.87px solid #101828;
                        padding: 5.21px 12.04px;
                        border-radius: 6.08px;
                        background: transparent;
                        position: absolute;
                        bottom: -58%;
                        width: 219.07px;
                        height: 31.29px;
                        right: -12%;
                        cursor: pointer;

                        p {
                            font-weight: 500;
                            font-size: 11.3px;
                            line-height: 14.71px;
                            color: #101828;
                            font-family: var(--dm-sans);
                        }
                    }
                }

                .top {
                    display: flex;
                    flex-direction: column;
                    gap: 17px;
                    align-items: flex-start;

                    .arrow3 {
                        position: absolute;
                        width: 92.5px;
                        height: 100.67px;
                        top: -25%;
                        right: 106%;
                    }

                    .protein {
                        position: absolute;
                        width: 160.76px;
                        height: 110.64px;
                        top: -140%;
                        right: 107%;
                        max-width: none;
                    }
                }

                .bottom {
                    // max-width: 158.17px;
                    display: flex;
                    flex-direction: column;
                    gap: 17.38px;
                    align-items: flex-start;

                    .arrow4 {
                        position: absolute;
                        width: 190.1px;
                        height: 128.27px;
                        bottom: -9px;
                        right: 105%;
                        max-width: none;
                    }
                }
            }

            .scrollSection {
                padding: 24px 16px 12px;
                display: flex;
                align-items: center;
                gap: 12px;
                overflow-x: auto;
                width: 100vw;
                @include hideScrollbar;

                &:first-child {
                    margin-left: 16px
                }

                &:last-child {
                    margin-right: 16px;
                }

                @include desktop {
                    display: none;
                }

                .cardWrapper {
                    background-color: #FAFAF9;
                    border-radius: 12px;
                    padding: 20px 16px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: auto;
                    min-width: 225px;
                    flex-shrink: 0;
                    height: 205px;

                    .topSection {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        gap: 20px;

                        .productImage {
                            width: 90px;
                            height: 101px;
                        }

                        .contentBox {
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            gap: 2px;

                            .content {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .icon {
                                    width: 35px;
                                    height: 35px;
                                }

                                .description {
                                    font-weight: 600;
                                    color: #104246;
                                    font-size: 15px;
                                    line-height: 20px;
                                    font-family: var(--dm-sans);

                                    span {
                                        font-size: 13.9px;
                                        line-height: 17.38px;
                                        font-weight: 500;
                                        color: #104246;
                                        font-family: var(--dm-sans);
                                    }
                                }
                            }
                        }
                    }

                    .contentBox {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 2px;

                        .content {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .icon {
                                width: 35px;
                                height: 35px;
                            }

                            .description {
                                font-weight: 600;
                                color: #104246;
                                font-size: 15px;
                                line-height: 20px;
                                font-family: var(--dm-sans);

                                span {
                                    font-size: 13.9px;
                                    line-height: 17.38px;
                                    font-weight: 500;
                                    color: #104246;
                                    font-family: var(--dm-sans);
                                }
                            }
                        }
                    }

                    .actionButton {
                        border: 0.87px solid #101828;
                        padding: 10.43px 13.04px;
                        border-radius: 6.08px;
                        background: transparent;

                        p {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15.62px;
                            color: #101828;
                            font-family: var(--dm-sans);
                        }
                    }
                }
            }

            .proteinometerWrapper2 {
                padding: 12px 20px 28px;
                z-index: 2;
                cursor: pointer;

                @include desktop {
                    padding: 0;
                    position: absolute;
                    bottom: 12%;
                    right: 12%;
                    display: none;
                }

                .proteinometer {
                    padding: 20.86px 13.9px;
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    background-color: #4F6034;
                    border-radius: 10.43px;

                    @include desktop {
                        padding: 20px 14px;
                        gap: 16px;
                    }

                    .contentWrapper {
                        display: flex;
                        align-items: center;
                        gap: 13.9px;

                        .icon {
                            width: 34.76px;
                            height: 34.76px;
                        }

                        .description {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 500;
                            font-family: var(--dm-sans);
                            color: #D4E4C3;

                            @include desktop {
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }

                    .icon {
                        width: 20.86px;
                        height: 20.86px;
                    }
                }
            }

            // &::after {
            //     content: '';
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     right: 0;
            //     height: 100px; /* Adjust height for the gradient */
            //     background: linear-gradient(180deg, rgba(255, 254, 252, 0) 0%, #FFFEFC 89.29%);
            //     pointer-events: none; /* Prevent blocking interactions */
            //   }
        }
    }
}