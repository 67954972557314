@import "@/styles/mixins";

.cardContainer{
    position: relative;
    background-size: cover !important;
    background-position: center, center 70% !important;
    background-repeat: no-repeat !important;
    width: 100%;
    display: flex;
    height: 428px;
    padding: 40px 24px;
    @include mobile{
        margin-bottom: 288px;
    }
    @include desktop{
        align-items: center;
        border-radius: 24px;
        padding: 40px 0px 40px 140px;
        height: 460px;
        max-width: 1416px;
    }
    .leftSide{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        @include desktop{
            max-width: 636px;
        }
        .title{
            font-size: 36px;
            line-height: 46.87px;
            font-weight: 600;
            font-family: var(--dm-sans);
            letter-spacing: -1px;
            color: #FFFFFF;
            @include desktop{
                font-size: 64px;
                line-height: 83.33px;
            }
        }
        .description{
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: var(--dm-sans) !important;
            color: #FFFFFF;
            margin-bottom: 14px;
            @include desktop{
                opacity: 0.9;
                margin-bottom: 22px;
                font-size: 24px;
                line-height: 40px;
                letter-spacing: normal;
            }
        }
        .buttonWrapper{
            display: flex;
            align-items: center;
            gap: 16px;
            .primaryCta{
                height: 48px;
                padding: 12px 18px 12px 22px;
                border-radius: 10px;
                background-color: #FFFFFF;
                background-image: none;
                cursor: pointer;
                p{
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 600;
                    font-family: var(--dm-sans);
                    color: #1D2939;
                    @include desktop{
                        font-size: 16px;
                    }
                }
                .icon{
                    @include max-width(375px){
                        display: none;
                    }
                    margin-left: 8px;
                    height: 23px;
                    width: 19px;
                }
            }
            .secondaryCta{
                height: 48px;
                padding: 12px 16px 12px 20px;
                border-radius: 10px;
                background-color: transparent;
                background-image: none;
                border: 1px solid #FFFFFF;
                cursor: pointer;
                p{
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 600;
                    font-family: var(--dm-sans);
                    color: #FFFFFF;
                    @include desktop{
                        font-size: 16px;
                    }
                }
                .icon{
                    @include max-width(375px){
                        display: none;
                    }
                    margin-left: 8px;
                    height: 23px;
                    width: 19px;
                }
            }
        }
    }
    .rightSide{
        position: absolute;
        top: 60%;
        left: 60%;
        height: 440px;
        width: 328px;
        @include mobile{
            -ms-transform: translate(-40%, 0%);
            transform: translate(-60%, 0%);
        }
        @include desktop{
            width: 400px;
            height: 560px;
            right: 5%;
            top: 12%;
        }
        .cardImage{
            width: 100%;
            height: 100%;
        }
    }
}