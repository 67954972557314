@import "@/styles/mixins";

.bootcampCarousel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile{
        margin-bottom: 8px;
    }
    .conatiner {
        padding: 0px;
        max-width: 100%;
        position: relative;
        overflow: hidden;
        @include desktop{
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 25%;
                width: clamp(50px, 40vw, 400px);
                transform: translateX(-100%);
                background: linear-gradient(90deg, #FAF7F4 30%, rgba(250, 247, 244, 0.8) 80%, rgba(250, 247, 244, 0) 100%);
                z-index: 1;
                // max-width: 100vw;
                // overflow: hidden;
                // clip-path: inset(0 0 0 0);
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 25%;
                width: clamp(50px, 40vw, 400px);
                transform: translateX(100%);
                background: linear-gradient(270deg, #FAF7F4 30%, rgba(250, 247, 244, 0.8) 80%, rgba(250, 247, 244, 0) 100%);
                z-index: 1;
                // clip-path: inset(0 0 0 0);
                // max-width: 100vw;
                // overflow: hidden;
            }
        }
        @media (max-width: 1300px) {
            &::after {
                right: 0; /* Adjust so it aligns with the right edge */
                transform: translateX(0); /* Prevent overflow */
                width: 20vw; /* Keep it responsive */
            }
        }
        :global(.slick-slider) {
            position: relative;

            :global(.slick-list) {

                :global(.slick-track) {
                    display: flex;
                    @include mobile {
                        display: flex;
                        gap: 10.24px;
                        margin: 0 -56px;
                    }

                    :global(.slick-slide) {
                        width: 292px !important;

                        // @include min-width(){
                        //     width: 318px !important;
                        // }
                        @include desktop {
                            padding: 0 12px;
                            width: 480px !important;
                        }
                    }

                    // &::before{
                    //     width: 5%;
                    //     height: 306px;
                    //     position: absolute;
                    //     left: 25%;
                    //     background: linear-gradient(360deg, #FAF7F4 15.85%, rgba(250, 247, 244, 0.8) 51.63%, rgba(250, 247, 244, 0) 99.85%);
                    // }
                    // &::after{
                    //     width: 100px;
                    //     height: 306px;
                    //     position: absolute;
                    //     right: 5%;
                    //     background: linear-gradient(360deg, #FAF7F4 15.85%, rgba(250, 247, 244, 0.8) 51.63%, rgba(250, 247, 244, 0) 99.85%);
                    // }
                }
            }
        }

        :global(.slick-arrow) {
            border: none !important;
            background-color: transparent !important;
            box-shadow: none !important;
            padding: 0 !important;
            position: absolute;
            transform: translateY(-50%);
            padding: 0;
            height: 48px;
            width: 48px;
            z-index: 2;

            >div {
                background: none !important;
                height: 48px;
                width: 48px;
            }

            &::before {
                content: none;
            }

            svg {
                height: 48px;
                width: 48px;
            }
        }

        :global(.slick-prev) {
            left: 8%;
        }

        :global(.slick-next) {
            right: 8%;
        }
    }
}