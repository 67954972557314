@import "@/styles/mixins";

.heroWrapper {
    background-color: #F3F3EF;

    .heroContainer {
        padding: 20px 16px 22px;

        @include desktop {
            padding: 50px 40px 33px;
        }

        .cardWrapper {
            position: relative;
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            width: 100%;
            display: flex !important;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            height: 600px;
            border-radius: 16px;
            
            @include desktop{
                border-radius: 20px;
            }

            .title {
                font-family: var(--circularStd);
                font-size: 48px;
                line-height: 60.72px;
                font-weight: 450;
                color: #FFFFFF;
                text-align: center;

                @include desktop {
                    font-size: 72px;
                    line-height: 91.08px;
                }

                span {
                    font-weight: 700;
                    font-style: italic;
                    font-size: 64px;
                    line-height: 83.2px;
                    color: #FFFFFF;
                    font-family: var(--boska);

                    @include desktop {
                        font-size: 100px;
                        line-height: 130px;
                    }
                }
            }

            .description {
                font-family: var(--dm-sans);
                font-size: 20px;
                line-height: 32px;
                font-weight: 500;
                color: #FFFFFF;
                text-align: center;
                text-align: center;
                opacity: 0.9;
                @include mobile{
                    max-width: 348px;
                    padding: 0 24px;
                }
                @include desktop {
                    max-width: 80%;
                    font-size: 24px;
                    line-height: 40px;
                }
            }
        }
    }
}