@import "@/styles/mixins";

.bootcampListing {
  @include desktop {
    margin: 56px auto;
  }
  .conatiner {
    padding: 32px 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 24px;
    background: #ffffff;
    @include desktop {
      padding: 0 16px;
      padding-bottom: 0px;
      gap: 40px;
    }
    .heading {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 8px;
      text-align: center;
      padding: 0 20px;
      @include desktop {
        gap: 16px;
        padding: 0 72px;
      }
      .title {
        color: #101828;
        font-family: var(--dm-sans);
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        @include desktop {
          font-size: 48px;
          line-height: 62.5px;
        }
      }
      .description {
        color: #1D2939;
        font-family: var(--dm-sans);
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        @include desktop {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
    .content {
      width: 100%;
      margin-bottom: 16px;
      @include desktop {
        margin-bottom: 24px;
      }
    }
    .actionButton {
      border-radius: 10px;
      background: #1D2939;
      padding: 12px 24px;
      height: 48px;
      p {
          @include flex-center;
          gap: 12px;
          color: #FFFFFF;
          font-family: var(--dm-sans);
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
      }

      .icon {
          width: 20px;
          height: 20px;
      }
  }
  }
}
