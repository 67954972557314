@import "@/styles/mixins";

.previewImageModal{
    height: 570px !important;
    @include desktop{
        height: 660px;
    }
    :global(.icon){
        height: 30px;
        width: 30px;
        top: 10px;
        right: 11px;
        @include desktop{
            top: 8px;
            right: 24px;
            height: 37px;
            width: 37px;
        }
    }
    .imageWrapper{
        @include hideScrollbar;
        height: 100%;
        position: relative;
        @include desktop{
            height: 660px;
        }
    }
}