@import "@/styles/mixins";

.faqSectionWrapper {
  max-width: 1312px;
  margin: 0 auto;
  width: 100%;
  .faqSection {
    display: flex;
    padding: 40px 16px 48px 16px;
    flex-direction: column;
    gap: 24px;
    background: #fff;
    width: 100%;
    @include desktop {
      padding: 72px 0px;
      align-self: stretch;
      max-width: 1150px;
      margin: 0 auto;
    }
    .topSection {
      display: flex;
      flex-direction: column;
      gap: 4px;
      @include desktop {
        gap: 12px;
        width: 100%;
      }
      .title {
        color: #101828;
        font-family: var(--man);
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        @include desktop {
          font-size: 26px;
          line-height: 32px;
        }
      }
      .description {
        color: #101828;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        @include desktop {
          font-size: 16px;
        }
      }
    }
    .bottomSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      width: 100%;
      .accordionAnswer {
        font-family: var(--man);
        color: var(--primary-oxford-blue, #001f49);
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
        opacity: 0.8;
        ul li{
          font-family: var(--man);
          color: var(--primary-oxford-blue, #001f49);
          font-size: 14px;
          line-height: normal;
          font-weight: 400;
          opacity: 0.8;
          @include desktop {
            font-size: 16px;
            line-height: 22px;
            max-width: 927px;
          }
        }
        @include desktop {
          font-size: 16px;
          line-height: 22px;
          max-width: 927px;
        }
      }
    }
  }
}

.benefitSectionHideWeb{
  .faqSection{
    .topSection{
      .title{
        font-size: 24px;
        line-height: 32px;
        font-family: var(--man-700);
        @include desktop{
          font-size: 32px !important;
          line-height: 40px !important;
        }
      }
    }
    .bottomSection{
      :global(.accordion){
        background-color: #F9FAFB;
        border: 2px solid #FFFFFF;
        :global(.header){
          :global(.title){
            color: #101828;
            font-family: var(--man-700);
            line-height: 24px;
          }
        }
      }
      .accordionAnswer{
        line-height: 24px;
        color: #001F49;
        font-family: var(--man-500);
      }
    }
  }
}
