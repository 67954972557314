@import "@/styles/mixins";

.communityCard {
    padding: 48px 20px 24px;

    @include desktop {
        padding: 100px 0px 50px;
    }

    .container {
        padding: 32px 0px 20px 20px;
        border-radius: 16px;
        background-color: #1D2939;
        display: flex;
        align-items: center;
        gap: 44px;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
            max-width: 1240px;
            padding: 56px 0px 56px 64px;
            gap: 72px;
            border-radius: 24px;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            gap: 8px;
            @include mobile{
                padding-right: 12px;
            }
            @include desktop{
                gap: 24px;
                max-width: 526px;
            }
            .title {
                font-size: 32px;
                line-height: 40px;
                font-weight: 600;
                font-family: var(--dm-sans);
                color: #BDB4FE;
                
                @include desktop {
                    letter-spacing: -1px;
                    font-size: 64px;
                    line-height: 72px;
                }
            }

            .description {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                font-family: var(--dm-sans);
                opacity: 0.8;
                color: #FFFFFF;
                @include mobile{
                    margin-bottom: 16px;
                }
                @include desktop {
                    letter-spacing: -1px;
                    font-size: 24px;
                    line-height: 40px;
                }

                span {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    font-family: var(--dm-sans);
                    letter-spacing: -1px;
                    opacity: 0.8;
                    color: #BDB4FE;

                    @include desktop {
                        font-size: 24px;
                        line-height: 40px;
                    }
                }
            }

            .actionButton {
                border-radius: 10px;
                background: #FFFFFF;
                padding: 16px 20px;
                height: 48px;

                p {
                    @include flex-center;
                    gap: 12px;
                    color: #1D2939;
                    font-family: var(--dm-sans);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                }

                .icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .banner {
            position: relative;
            overflow: hidden;
            height: 264px;

            @include desktop {
                flex: 1;
            }

            .cardImage {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include mobile {
                    display: none;
                }
            }

            .mWebCardImage {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include mobile{
                    border-radius: 12px 0 0 12px;
                }
                @include desktop {
                    display: none;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                width: 20%;
                height: 100%;
                background: linear-gradient(270deg,
                        rgba(29, 41, 57, 0) 0%,
                        rgba(29, 41, 57, 0.6) 30%,
                        #1d2939 100%);
                pointer-events: none;

                @include mobile {
                    display: none;
                }
            }
        }
    }
}