@import "@/styles/mixins";

.bootcampListing {
    background: #FAF7F4;

    .conatiner {
        padding: 36px 0px 48px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: center;

        @include desktop {
            gap: 48px;
            padding: 70px 0px 100px;
        }

        .heading {
            display: flex;
            flex-direction: column;
            gap: 8px;
            text-align: center;
            max-width: 388px;
            @include mobile{
                margin-bottom: 8px;
            }
            @include desktop {
                gap: 16px;
                max-width: 900px;
            }

            .title {
                color: #101828;
                font-family: var(--dm-sans);
                font-size: 32px;
                line-height: 41.66px;
                font-weight: 600;

                @include desktop {
                    letter-spacing: -1px;
                    font-size: 64px;
                    line-height: 83.33px;
                }
            }

            .description {
                color: #1D2939;
                font-family: var(--dm-sans);
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;

                @include desktop {
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }

        .metrics {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 24px;
            max-width: 367.71px;
            @include mobile{
                margin-bottom: 8px;
            }
            @include desktop {
                gap: 64px;
                max-width: 1272px;
            }
            .content {
                display: flex;
                flex-direction: column;
                gap: 2.33px;
                align-items: center;
                gap: 2px;
                @include desktop {
                    gap: 4px;
                }

                .title {
                    font-weight: 600;
                    font-family: var(--dm-sans);
                    font-size: 32px;
                    line-height: 41.66px;
                    color: #1D2939;
                    position: relative;
                    text-transform: uppercase;
                    display: flex;
                    align-items: flex-start;
                    gap: 4px;
                    @include desktop {
                        font-size: 72px;
                        line-height: 93.74px;
                    }

                    span {
                        font-family: var(--dm-sans);
                        color: #1D2939;
                    }
                }

                .description {
                    font-weight: 500;
                    font-family: var(--dm-sans);
                    font-size: 12px;
                    line-height: 15.62px;
                    color: #667085;
                    text-align: center;

                    @include desktop {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 23.44px;
                    }
                }
            }
            &:nth-child(1) {
                .content {
                    .title {
                        span {
                            @include mobile {
                                font-family: var(--dm-sans);
                            }
                        }
                    }
                }
            }

            &:nth-child(2) {
                .content {
                    .title {
                        span {
                            font-family: var(--dm-sans) !important;

                            @include mobile {
                                font-family: var(--man-600) !important;
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                .content {
                    .title {
                        span {
                            font-family: var(--man-500);
                        }
                    }
                }
            }
        }

        .actionButton {
            border-radius: 10px;
            background: #1D2939;
            padding: 12px 24px;
            height: 48px;

            p {
                @include flex-center;
                gap: 8px;
                color: #FFFFFF;
                font-family: var(--dm-sans);
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
            }

            .icon {
                width: 20px;
                height: 20px;
            }
        }
    }
}