@import "@/styles/mixins";

.marquee-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #6E55D3;
    padding: 4px 0;
    @include desktop{
        padding: 6px 0px;
    }
    .marquee {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        animation: marquee 22s linear infinite;
        will-change: transform;
        overflow-x: hidden;
        .dot {
            margin: 0 8px;
            font-size: 20px;
            color: #F4F3FF;
        }

        .text {
            text-decoration: none;
            color: #F4F3FF;
            font-size: 14px;
            line-height: 17.71px;
            margin: 0 10px;
            transition: color 0.3s ease;
            font-weight: 450;
            cursor: pointer;
        }
    }
}
@keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }