@import "@/styles/mixins";

.services{
    .contianer{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include desktop{
            padding: 100px 48px 150px;
            gap: 160px;
        }
    }
}